// import { Switch } from 'antd';
// import Image from 'next/image';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from 'react'

// import FilterForMap from './FilterForMap';
// import { LineDotFilterIcon } from '../../../util/constant/ICON';
import SearchBar from '../../../component/map/SearchBar';
import { LineDotFilterIcon } from "../../../asset/icons";
import { Image, Switch } from "antd";

// let isRotation = false;
// let rotationTimeout = false;
const MapBottomButtons = ({
    // isFilter,
    // isSharkToggle,
    // isNudeToggle,
    map,
    // setIs3D,
    // is3D,
    // isswitchHandle,
    isPopupBeachData,
    isLoadingCount,
    enableToSpot,
    setEnableToSpot,
    toggleShowSpots,
    isShowSpot,
    // activityStatus,
    // setActivityStatus,
    // openDrawer,
    // setOpenDrawer,
    // setQuery,
    // query

}) => {
    // const [isRotate, setIsRotate] = useState(false);
    // const rotationAnimation = useRef(null);
    // useEffect(() => {
    //     if (!map.current) return
    //     map.current.on('mousedown', () => {
    //         stopRotation();
    //     });

    //     map.current.on('dragstart', () => {
    //         stopRotation();
    //     });

    //     map.current.on('dragend', () => {

    //         if (!+localStorage.getItem("isRotation")) {
    //             stopRotation();
    //         } else {
    //             restartRotationWithDelay();
    //         }
    //     });
    // }, [map, isRotate])

    // const rotateMap = () => {
    //     map.current.setBearing((map.current.getBearing() + 0.1) % 360);
    //     rotationAnimation.current = requestAnimationFrame(rotateMap);
    // };
    // const startRotation = () => {
    //     if (!rotationAnimation.current) {
    //         rotationAnimation.current = requestAnimationFrame(rotateMap);
    //         setIsRotate(true);
    //     }
    // };
    // function restartRotationWithDelay() {
    //     clearTimeout(rotationTimeout);
    //     rotationTimeout = setTimeout(() => {
    //         if (!isRotate) {
    //             startRotation();
    //         }
    //     }, 1000);
    // }
    // const stopRotation = () => {
    //     if (rotationAnimation.current) {
    //         cancelAnimationFrame(rotationAnimation.current);
    //         setIsRotate(false);
    //         rotationAnimation.current = null;
    //     }
    // };


    // const toggle3D = () => {
    //     setIs3D(!is3D);

    //     if (!is3D) {
    //         map.current.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
    //         map.current.setPitch(60);
    //         map.current.setBearing(-17.6);
    //         // startRotation();
    //     } else {
    //         map.current.setTerrain(null);
    //         map.current.setPitch(0);
    //         map.current.setBearing(0);
    //         // stopRotation();
    //     }
    // };
    // const sateliteHandle = async () => {
    //     await localStorage.setItem("isSatelite", isswitchHandle?.isSatelite == 0 ? 1 : 0);
    //     // await localStorage.setItem("isSatelite", isSatelite == 0 ? 1 : 0);
    //     window.location.reload();
    //     // setIsSatelite(pr => !pr)
    // };
    return (
        <>
            {/* {isFilter && <FilterForMap
                activityStatus={activityStatus}
                setActivityStatus={setActivityStatus}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                setQuery={setQuery}
                query={query}
            />} */}
            <div className="absolute top-5 left-4 z-10 md:hidden">
                <SearchBar
                    onMap
                    // rotationAnimation={rotationAnimation}
                    // stopRotation={stopRotation}
                    // isRotation={isRotation}
                    // startRotation={startRotation}
                    // restartRotationWithDelay={restartRotationWithDelay}
                    mapData={{
                        map: map,
                        mapboxgl: mapboxgl,
                        isPopupBeachData: isPopupBeachData,
                    }}
                />
            </div>
            <div className="absolute xs:relative xs:bottom-4 xs:left-1/2 xs:transform xs:-translate-x-1/2 md:top-4 md:left-4 z-10 flex gap-x-2 ">
                <div className="md:block hidden">
                    <SearchBar
                        onMap
                        // rotationAnimation={rotationAnimation}
                        // stopRotation={stopRotation}
                        // isRotation={isRotation}
                        // startRotation={startRotation}
                        // restartRotationWithDelay={restartRotationWithDelay}
                        mapData={{
                            map: map,
                            mapboxgl: mapboxgl,
                            isPopupBeachData: isPopupBeachData,
                        }}
                    />
                </div>


                {/* <button
                    className=" md:rounded-md md:border xs:-bottom-2 xs:left-2 xs:absolute md:border-gray-200 md:bg-white bg-sandee-blue md:p-[7.5px] xs:py-1 xs:px-4 rounded-full w-fit shadow-md"
                    onClick={() => {
                        setEnableToSpot(pr => !pr);
                    }}
                >
                    <div className="flex items-center gap-x-2">
                        <LineDotFilterIcon className="w-5 h-5 md:stroke-black stroke-white" />
                        <span className="md:hidden text-white">Add Spot</span>
                    </div>
                </button> */}
                <div className="flex xs:absolute xs:bottom-8 xs:left-2 gap-x-2 xs:gap-x-2 items-center md:rounded-md md:border md:border-gray-200 md:bg-white bg-sandee-blue md:p-[3px] md:px-2 xs:py-1 xs:px-3 rounded-full w-fit shadow-md">
                    {/* <Image
                        src={"/Shark.svg"}
                        alt="shark"
                        width={30}
                        height={30}
                    /> */}
                    <p className="text-lg xs:text-base">Add Custom Spot </p>
                    <div className="shark-switch ">
                        <Switch
                            id={"switch-Spot"}
                            key={"switch-Spot"}
                            className=""
                            value={enableToSpot}
                            onChange={async () => {
                                setEnableToSpot(pr => !pr);
                            }}
                        />
                    </div>
                </div>
                <div className="cursor-pointer flex xs:absolute xs:bottom-8 xs:left-2 gap-x-2 xs:gap-x-2 items-center md:rounded-md md:border md:border-gray-200 md:bg-white bg-sandee-blue md:p-[3px] md:px-2 xs:py-1 xs:px-3 rounded-full w-fit shadow-md">
                    {/* <Image
                        src={"/Shark.svg"}
                        alt="shark"
                        width={30}
                        height={30}
                    /> */}
                    <p className="text-lg xs:text-base" onClick={() => {
                        toggleShowSpots();
                    }}>{isShowSpot ? "Show Spot" : "Hide Spot"}</p>
                    {/* <div className="shark-switch ">
                        <Switch
                            id={"switch-Spot"}
                            key={"switch-Spot"}
                            className=""
                            value={enableToSpot}
                            onChange={async () => {
                                setEnableToSpot(pr => !pr);
                            }}
                        />
                    </div> */}
                </div>


                {/* {isFilter && <button
                    className=" md:rounded-md md:border xs:-bottom-2 xs:left-2 xs:absolute md:border-gray-200 md:bg-white bg-sandee-blue md:p-[7.5px] xs:py-1 xs:px-4 rounded-full w-fit shadow-md"
                    onClick={() => {
                        setOpenDrawer(!openDrawer);
                    }}
                >
                    <div className="flex items-center gap-x-2">
                        <LineDotFilterIcon className="w-5 h-5 md:stroke-black stroke-white" />
                        <span className="md:hidden text-white">Filter</span>
                    </div>
                </button>}
                {isSharkToggle && <div className="flex xs:absolute xs:bottom-8 xs:left-2 gap-x-2 xs:gap-x-2 items-center md:rounded-md md:border md:border-gray-200 md:bg-white bg-sandee-blue md:p-[5px] xs:py-1 xs:px-3 rounded-full w-fit shadow-md">
                    <Image
                        src={"/Shark.svg"}
                        alt="shark"
                        width={30}
                        height={30}
                    />
                    <p className="text-lg xs:text-base">Sharks {" "}</p>
                    <div className="shark-switch ">
                        <Switch
                            id={"switch-sharks"}
                            key={"switch-sharks"}
                            className=""
                            value={isswitchHandle?.isShark}
                            onChange={async () => {
                                await localStorage.setItem("isShark", isswitchHandle?.isShark == 0 ? 1 : 0);
                                await localStorage.setItem("isNude", 0);
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>}
                {isNudeToggle && <div className="flex xs:absolute xs:bottom-8 xs:right-2 gap-x-2 items-center md:rounded-md md:border md:border-gray-200 md:bg-white bg-sandee-blue md:p-[5px] xs:py-1 xs:px-3 rounded-full w-fit shadow-md">
                    <Image
                        src={"/Nude-Beach.svg"}
                        alt="nude beach"
                        width={25}
                        height={25}
                    />
                    <p className="text-lg">Nudes {" "}</p>
                    <div className="nude-switch ">
                        <Switch
                            id={"switch-nudes"}
                            key={"switch-nudes"}
                            className=""
                            value={isswitchHandle?.isNude}
                            onChange={async () => {
                                await localStorage.setItem("isNude", isswitchHandle?.isNude == 0 ? 1 : 0);
                                await localStorage.setItem("isShark", 0);
                                window.location.reload();

                            }}
                        />
                    </div>
                </div>} */}
            </div>
            {/* {isLoadingCount ? (
                <div className="absolute bg-[#00aae3] xs:absolute z-10 md:bottom-4 md:right-4 xs:left-4 xs:bottom-24 bg-sandee-blue py-1 rounded-lg md:px-2 px-2 text-white md:text-base text-[12px] ">
                    <span className="font-semibold">Loading Beaches...</span>
                </div>
            ) : null} */}
            {/* <button
                onClick={() => {
                    const isLocalRotate = localStorage.getItem("isRotation");
                    localStorage.setItem("isRotation", +localStorage.getItem("isRotation") ? 0 : 1);
                    if (rotationAnimation?.current && !isRotation) {
                        stopRotation();
                    } else {
                        restartRotationWithDelay();
                    }
                }}
                className="absolute h-[29px] md:bottom-4 bottom-4 right-[262px] pb-[1px] pt-[2px] px-2 text-sm shadow-md rounded-md bg-white z-10 hover:bg-gray-50 border border-gray-300"
            >
                <Image
                    src={`${!isRotate ? "/icon 360.svg" : "/icon stop.svg"}`}
                    alt="360 view"
                    width={23}
                    height={25}
                />

            </button> */}
            {/* <button
                onClick={sateliteHandle}
                className="absolute h-[29px] md:bottom-4 bottom-4 right-[155px] py-1 px-2 text-sm shadow-md rounded-md bg-white z-10 hover:bg-gray-50 border border-gray-300"
            >

                {isswitchHandle?.isSatelite ? "Satellite view" : "Street view"}
            </button>
            <button
                onClick={toggle3D}
                className="absolute h-[29px] md:bottom-4 bottom-4 right-28 py-1 px-2 text-sm shadow-md rounded-md bg-white z-10 hover:bg-gray-50 border border-gray-300"
            >
                {is3D ? "2D" : "3D"}
            </button> */}
        </>
    )
}

export default MapBottomButtons