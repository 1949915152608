import { Modal, Row, Tooltip } from 'antd';
import React, { useState } from 'react'
import CRUDComponent from '../../../../component/common/CRUD-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { EditorContent } from '../../../../util/functions';

const BeachFAQTab = ({ extraQuery = {}, ...props }) => {
    const [open, setOpen] = useState(null);
    return (
        <><Row>
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.faq.get,
                    extraQuery,
                    DataModifier: (res) => {
                        return res?.map((el) => ({
                            ...el,
                            no: `${el?.id}`.padStart(6, "0"),
                            faqQuestion:
                                <Tooltip title={el?.question}>
                                    <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{el?.question}</p></Tooltip>,
                            faqAnswer: {
                                //  link: `${appRoot}/${ROUTES.BEACH}/${el?.country?.slug}/${el?.state?.slug}/${el?.city?.slug}/${el?.nameSlug}`,
                                id: el?.id,
                                onClick: () => {
                                    setOpen({
                                        id: el?.id,
                                        answer: el?.answer
                                    });
                                }
                            },
                            // faqAnswer: <Tooltip title={el?.answer}>
                            //     <div className='whitespace-nowrap overflow-hidden overflow-ellipsis'>
                            //         <EditorContent
                            //             className="font-light "
                            //             value={el?.answer}
                            //         />
                            //     </div>
                            //     {/* <div className='whitespace-nowrap overflow-hidden overflow-ellipsis'  dangerouslySetInnerHTML={el?.answer ?? <></>} /> */}

                            //     {/* </div>
                            //     <p className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{el?.answer}</p> */}
                            // </Tooltip>
                            // userName: el?.user?.name,
                        }));
                    },
                    // column: CONSTANTS.TABLE.Beach_Suggestions,
                    column: CONSTANTS.TABLE.Beach_FAQs,
                }}
                CREATE={{
                    API: CONSTANTS.API.faq.create,
                    modalFields: CONSTANTS.FORM_FIELD.FAQ_MODAL,
                    message: "FAQ Added Successfully",
                    modaltitle: "Add FAQ",
                    position: "right",
                    name: "Add FAQ",
                    payloadModifier: (payload) => {
                        return {
                            ...payload,
                            AllBeachId: extraQuery?.AllBeachId,

                            // userId: localStorage.getItem("userId"),
                        }
                    }

                }}
                UPDATE={{
                    API: CONSTANTS.API.faq.update,
                    message: "FAQ Updated Successfully",
                    modaltitle: "Update FAQ",
                    modalFields: CONSTANTS.FORM_FIELD.FAQ_MODAL,
                    // payloadModifier: (payload) => {
                    //     return {
                    //         ...payload,
                    //         // AllBeachId: extraQuery?.AllBeachId
                    //         // userId: localStorage.getItem("userId"),
                    //     }
                    // }


                }}
                DELETE={{
                    API: CONSTANTS.API.faq.delete,
                    message: "Deleted Successfully",
                    confirm_message: "Are you sure to Delete this FAQ?",
                }}
                // FILTERSORTKEY={{
                //     userRole: "$user.role$",
                //     userName: "userId",
                //     userEmail: "$user.email$",
                //     // name: "$AllBeach.name$",
                // }}
                // isSearch
                selectionOff
                props={props}
            />
        </Row>
            <Modal
                title="FAQ Answer"
                open={open !== null}
                onCancel={() => setOpen(null)}
                footer={null}
                centered
                children={<>
                    <EditorContent
                        className="font-light "
                        value={open?.answer ?? <></>}
                    />
                </>}
                width={600}

            />


        </>
    )
}

export default BeachFAQTab