import { Breadcrumb, Button, Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import CustomDND from "../../../component/common/Custom-DND";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { apiGenerator } from "../../../util/functions";

const filterObjeData = {
  country: {
    swapEndPoint: "countrySwapIndex",
    getEndPoint: "countryGet",
    updateEndPoint: "countryUpdate",
    id: "countryId",
    modalSelect: "EDIT_HOME_COUNTRY",
    getOption: "getCountryOption",
    editLable: "Edit Home Country",
  },
  beachBlog: {
    swapEndPoint: "blogSwapIndex",
    getEndPoint: "blogGet",
    updateEndPoint: "blogUpdate",
    id: "blogId",
    modalSelect: "EDIT_HOME_BLOGS",
    getOption: "getListicleBlogOption",
    searchEndPoint: "/admin/blog/titles",
    editLable: "Edit Beach Blog",

  },
  beachList: {
    swapEndPoint: "listicleSwapIndex",
    getEndPoint: "listicleGet",
    updateEndPoint: "listicleUpdate",
    id: "beachListicleId",
    modalSelect: "EDIT_HOME_BEACHS",
    getOption: 'getListicleOption',
    searchEndPoint: "/admin/listiclesMain",
    editLable: "Edit Beach List",
  },
}

const HomeCountry = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [sectionType, setSectionType] = useState("country")
  const API = useHttp();
  const api = useHttp();
  useEffect(() => {
    api.sendRequest(CONSTANTS.API?.Home?.[filterObjeData?.[sectionType]?.getEndPoint], (res) => {
      const resData = res?.data?.rows ? res?.data?.rows : res?.data;
      const updatedList = resData?.map((i, index) => {
        return {
          ...i,
          order: index + 1
        };
      })
      setList(updatedList);
    });
  }, [refresh, sectionType]);
  useEffect(() => {
    if (isOpenModal) {
      if (filterObjeData?.[sectionType]?.getOption) {
        const apiCountry = { ...CONSTANTS.API.Home?.[filterObjeData?.[sectionType]?.getOption] };
        // // apiCountry.endpoint = apiCountry.endpoint + "?limit=300";
        if (sectionType !== "country") {
          apiCountry.endpoint = apiCountry.endpoint + `?searchQuery=${selectedData?.name ?? selectedData?.title ?? ""}&limit=1000`;
        }
        API.sendRequest(apiCountry, (res) => {
          const resData = res?.data?.rows ? res?.data?.rows : res?.data;
          const options = resData?.map((i) => {
            return {
              ...i,
              label: i?.name ?? i?.title,
              value: i?.id,
              id: i?.id,
            };
          });
          const countryField = CONSTANTS?.FORM_FIELD?.[filterObjeData?.[sectionType]?.modalSelect]?.find(
            (el) => el?.id === filterObjeData?.[sectionType]?.id
          );
          if (countryField) {
            countryField.option = options;
          }
        });
      }

    }
  }, [isOpenModal]);

  const handleEdit = (data) => {
    const country = { ...CONSTANTS.API.Home?.[filterObjeData?.[sectionType]?.updateEndPoint] };
    const apiCountry = apiGenerator(country, { id: data?.[filterObjeData?.[sectionType]?.id] });
    const payload = {
      index: selectedData?.index ?? selectedData?.order,
    };
    API.sendRequest(
      apiCountry,
      (res) => {
        setIsOpenModal((prv) => !prv);
        setRefresh((prv) => !prv);
      },
      payload,
      "Edit Successfully!!!"
    );
  };

  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.BEACH}`,
            title: "Home Section",
          },

        ]}
      />
      <Row className="mt-6" gutter={[6, 8]}>
        <Col>
          <Button
            onClick={() => {
              setSectionType("country");

            }}
            type={`${sectionType === "country" ? "primary" : "default"}`}
          >
            Country Listicles
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setSectionType("beachBlog");
            }}
            type={`${sectionType === "beachBlog" ? "primary" : "default"}`}
          >
            Beach Blogs
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setSectionType("beachList");
            }}
            type={`${sectionType === "beachList" ? "primary" : "default"}`}
          >
            Beach Lists
          </Button>
        </Col>
      </Row>
      <Card
        // styles={{
        //   body: {
        //     height: list?.length == 9 ? "300px" : "200px"
        //   }
        // }}
        className={`my-5 `} >
        {/* <h1 className="text-2xl font-semibold">Countries</h1> */}
        {/* {API?.isLoading ? <Spin className={`!h-96 flex items-center justify-center`} /> :  */}
        <CustomDND
          data={list}
          setRefresh={setRefresh}
          editApi={CONSTANTS.API?.Home?.[filterObjeData?.[sectionType]?.swapEndPoint]}
          payloadIdKey={"id"}
          grid={list?.length == 9 ? 3 : 4}
          height={list?.length == 9 ? 300 : 200}
          component={(res) => {
            return (
              <Card
                cover={
                  <img alt="example" src={res?.image?.imageUrl || res?.listicleImage?.imageUrl || res?.image} height={190} />
                }
                bodyStyle={{ padding: "8px" }}
                actions={[
                  <EditOutlined
                    key="edit"
                    src={res?.image[1]?.imageUrl || null}
                    onClick={() => {
                      setSelectedData(res);
                      setIsOpenModal((prv) => !prv);
                    }}
                  />,
                ]}
              >
                <h2 className="truncate text-center text-lg !mt-0 !mb-0" title={res?.name || res?.title}>
                  {res?.name || res?.title}
                </h2>
              </Card>
            );
          }}
        />
      </Card>
      {isOpenModal ? (
        <ModalFormCreator
          open={isOpenModal}
          onCreate={handleEdit}
          onCancel={() => {
            setIsOpenModal((prev) => !prev);
          }}
          name={filterObjeData?.[sectionType]?.editLable}
          menuFields={CONSTANTS?.FORM_FIELD?.[filterObjeData?.[sectionType]?.modalSelect]}
          loading={API.isLoading}
          formData={{ ...selectedData, [filterObjeData?.[sectionType]?.id]: selectedData?.id }}
        />
      ) : null}
    </>
  );
};

export default HomeCountry;
