import { Breadcrumb, Button, Card, Row } from "antd";
import React, { useEffect } from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { ROLES } from "../../../util/Route";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { getClearObject } from "../../../util/functions";
import moment from "moment";

const SharksAttacks = (props) => {
  const api = useHttp();
  useEffect(() => {
    const speciesApi = { ...CONSTANTS.API.SharkSpecies.getAll };
    api.sendRequest(speciesApi, (res) => {
      const speciesList = res?.data?.rows?.map((i) => {
        return {
          ...i,
          label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      speciesList?.unshift({ label: "Unknown", value: null, id: null });
      CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL.find(
        (el) => el?.id === "sharkSpeciesId"
      ).option = speciesList;
      CONSTANTS.FORM_FIELD.SHARK_ATTACKS_EDIT_MODAL.find(
        (el) => el?.id === "sharkSpeciesId"
      ).option = speciesList;

    });

    const beachApi = { ...CONSTANTS.API.Beach.getAll };
    api.sendRequest(beachApi, (res) => {
      const beachList = res?.data?.rows?.map((i) => {
        return {
          ...i,
          label: i?.name,
          value: i?.id,
          // value: `${i?.id}-${i?.city?.id}-${i?.state?.id}-${i?.country?.id}`,
          id: i?.id,

        };
      });
      CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL.find(
        (el) => el?.id === "AllBeachId"
      ).option = beachList;
      CONSTANTS.FORM_FIELD.SHARK_ATTACKS_EDIT_MODAL.find(
        (el) => el?.id === "AllBeachId"
      ).option = beachList;
    });
  }, []);
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            // href: `${appRoot}/${ROUTES.Category}`,
            title: "Shark Attacks",
          },
        ]}
      />
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.SharkAttacks.getAll,
            DataModifier: (res) => {
              return res?.map((el) => ({
                ...el,
                no: `${el?.id}`.padStart(6, "0"),
                longitude: el?.GeoLoc?.coordinates[0] ?? "",
                latitude: el?.GeoLoc?.coordinates[1] ?? "",
                beachName: el?.AllBeach?.name ?? "",
                // date: moment(el?.date)?.locale("en"),
                // AllBeachId: `${el?.id}-${el?.city?.id}-${el?.state?.id}-${el?.country?.id}`,
                // AllBeachId: el?.AllBeachId ? el?.AllBeach?.name : null,
              }));
            },
            column: CONSTANTS.TABLE.SHARK_ATTACKS,
          }}
          UPDATE={{
            API: CONSTANTS.API.SharkAttacks.update,
            message: "Shark attacks updated successfully",
            modaltitle: "Update Shark Attacks",
            modalFields: CONSTANTS.FORM_FIELD.SHARK_ATTACKS_EDIT_MODAL,

            payloadModifier: (res, formData, value) => {
              let payload = getClearObject(res);
              if (payload?.longitude && payload?.latitude) {
                payload.GeoLoc = {
                  type: "Point",
                  coordinates: [payload.longitude, payload.latitude],
                };
              }
              if (payload?.date) {
                payload.date = payload?.date ? payload?.date == "Invalid date" ? null : `${moment(payload?.date).format(
                  "YYYY-MM-DD"
                )} ${moment(payload?.date, "HH:mm:ss").utc().format("HH:mm:ss")}` : null
                // payload.date = payload?.date ? payload?.date == "Invalid date" ? null : payload?.date : null


              }
              // if(payload?.date) {
              //   const dateTimeValue = `${moment(value[ele.id].$d).format(
              //     "YYYY-MM-DD"
              //   )} ${moment(value[ele.id].$d, "HH:mm:ss").utc().format("HH:mm:ss")}`;
              // }
              // console.log(res, formData, value)
              // const beachData = formData?.AllBeachId ? CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL?.find(
              //   el => el?.name === "AllBeachId"
              // )?.option?.find(ell => ell?.id === formData?.AllBeachId) : null
              // if (beachData) {
              //   payload["countryId"] = beachData?.country?.id;
              //   payload["stateId"] = beachData?.state?.id;
              //   payload["cityId"] = beachData?.city?.id;
              // }
              // if (formData?.AllBeachId && formData?.AllBeachId != "") {
              //   const parts = formData?.AllBeachId?.include('-') ? formData?.AllBeachId?.split('-') : [];
              //   payload["AllBeachId"] = parts?.[0];
              //   payload["countryId"] = parts?.[1];
              //   payload["stateId"] = parts?.[2];
              //   payload["cityId"] = parts?.[3];
              // }
              // console.log(payload, CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL)

              delete payload?.longitude;
              delete payload?.latitude;
              return payload;
            },
          }}
          CREATE={
            [
              ROLES.ADMIN,
              ROLES.DATA,
              // ROLES.IMAGE,
              // ROLES.INTERN,
              // ROLES.SEO,
              ROLES.SUPER_ADMIN,
              // ROLES.SUPERUSER,
              // ROLES.WRITER,
            ].some((el) => el === props?.profile?.role) && {
              name: "Add Shark Attacks",
              API: CONSTANTS.API.SharkAttacks.create,
              message: "Shark attacks created successfully",
              modaltitle: "Add Shark Attacks",
              modalFields: CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL,
              payloadModifier: (res, formData, value) => {
                let payload = getClearObject(res);
                // console.log(res, formData, value, CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL);
                if (payload?.longitude && payload?.latitude) {
                  payload.GeoLoc = {
                    type: "Point",
                    coordinates: [payload.longitude, payload.latitude],
                  };
                }
                if (payload?.date) {
                  payload.date = payload?.date ? payload?.date == "Invalid date" ? null : payload?.date : null

                }
                // const beachData = formData?.AllBeachId ? CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL?.find(
                //   el => el?.name === "AllBeachId"
                // )?.option?.find(ell => ell?.id === formData?.AllBeachId) : null
                // // console.log(beachData)
                // if (beachData) {
                //   payload["countryId"] = beachData?.country?.id;
                //   payload["stateId"] = beachData?.state?.id;
                //   payload["cityId"] = beachData?.city?.id;
                // }
                // if (formData?.AllBeachId && formData?.AllBeachId != "") {
                //   const parts = formData?.AllBeachId?.include('-') ? formData?.AllBeachId?.split('-') : [];
                //   payload["AllBeachId"] = parts?.[0];
                //   payload["countryId"] = parts?.[1];
                //   payload["stateId"] = parts?.[2];
                //   payload["cityId"] = parts?.[3];
                // }
                // console.log(payload, CONSTANTS.FORM_FIELD.SHARK_ATTACKS_MODAL)
                // payload["countryId"] = formData?.countryId;
                // payload["stateId"] = formData?.stateId;
                // payload["cityId"] = formData?.cityId;
                // console.log(payload)
                delete payload?.longitude;
                delete payload?.latitude;
                return payload;
              },
            }
          }
          DELETE={{
            API: CONSTANTS.API.SharkAttacks.delete,
            message: "Deleted Successfully",
            confirm_message: "Are you sure to Delete this Shark Attacks?",
          }}
          // FILTERSORTKEY={{
          //   cityName: "$city.name$",
          // }}
          isSearch
          selectionOff
          props={props}
        />
      </Row>
    </>
  );
};

export default SharksAttacks;
