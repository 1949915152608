import React, { useCallback, useEffect, useState } from 'react'
import BeachSample from '../map/BeachSample';
import { Alert, Breadcrumb, Card, Col, Row, Spin, Switch } from 'antd';
import useHttp from '../../../hooks/use-http';
import Mapbeach from './Map-beach';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const MapPin = (props) => {
    const [beaches, setBeaches] = useState([]);
    const [allBeachesData, setAllBeachesData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [onlyNear, setOnlyNear] = useState(false);
    const [isShowSpot, setIsShowSpot] = useState(false);
    const [spotData, setSpotData] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    const api = useHttp();
    // Handle toggling the visibility of spots
    const toggleShowSpots = useCallback(() => {
        setIsShowSpot((prev) => !prev); // Toggle the visibility
    }, []);
    const API = useHttp();
    useEffect(() => {
        // if (isShowSpot) return

        API.sendRequest(
            {
                type: "GET",
                endpoint: `/admin/beachMain/getAllBeaches?limit=100000&page=1`,
                // endpoint: `/admin/beachMain/getAllBeaches?limit=1000&page=1`,
            },
            (res) => {
                const AllBeachWithRefinedData = res?.data?.rows?.map((el) => ({
                    ...el,
                    lat: el?.GeoLoc?.coordinates?.[1] ?? 0,
                    long: el?.GeoLoc?.coordinates?.[0] ?? 0,
                    locationAdress: `<a href="${process.env.REACT_APP_WEB_URL}/${el?.country?.slug}/${el?.state?.slug}/${el?.city?.slug}/${el?.nameSlug}" target="_blank" >${el?.name}<br>${el?.city?.name}, ${el?.state?.name}, ${el?.country?.name} </a>`,
                }));
                // setAllBeachesData(AllBeachWithRefinedData);

                const groupedBeachesByCityId = AllBeachWithRefinedData.reduce(
                    (acc, beach) => {
                        const cityId = beach?.city?.id;
                        if (!acc[cityId]) {
                            acc[cityId] = [];
                        }
                        acc[cityId].push(beach);
                        return acc;
                    },
                    {}
                );
                const groupedBeaches = Object.values(groupedBeachesByCityId).map(
                    (beaches) => beaches
                );
                const processedBeaches = [];

                groupedBeaches?.forEach((cityWiseBeach) => {
                    const processedBeachesCityWise = setProximity(cityWiseBeach, 100);
                    // processedBeachesCityWise?.filter((el) => el?.isClose)?.length &&
                    //   console.log(processedBeachesCityWise?.filter((el) => el?.isClose));
                    processedBeaches.push(...processedBeachesCityWise);
                });

                setAllBeachesData(processedBeaches);
            }
        );
    }, []);
    useEffect(() => {
        if (isShowSpot) {
            setSpotData([])

        } else {

            api.sendRequest(
                CONSTANTS.API.customSpot.getSpot,
                (res) => {
                    setSpotData(res?.data?.rows)
                },
            );
        }
    }, [isShowSpot, isRefresh]);

    useEffect(() => {
        if (!allBeachesData?.length) {
            return;
        }
        let DataForMap = [...allBeachesData];

        if (selectedCity) {
            DataForMap = DataForMap?.filter((el) => el?.city?.id === selectedCity);
        }

        if (selectedState && !selectedCity) {
            DataForMap = DataForMap?.filter((el) => el?.state?.id === selectedState);
        }
        if (selectedCountry && !selectedState && !selectedCity) {
            DataForMap = DataForMap?.filter(
                (el) => el?.country?.id === selectedCountry
            );
        }
        if (onlyNear) {
            DataForMap = DataForMap?.filter((el) => el?.isClose);
        }
        return setBeaches(
            DataForMap?.map((el) => ({
                ...el,
                color: el?.isClose ? "red" : "black",
            }))
        );
    }, [allBeachesData, selectedCity, selectedState, selectedCountry, onlyNear]);
    return (
        <>
            <Breadcrumb
                className="mx-2 mb-3 mt-6"
                separator=" > "
                items={[
                    {
                        title: "Home",
                    },
                    {
                        title: "Map",
                    },
                ]}
            />
            <Row className="w-full" gutter={[16, 16]}>
                {/* <Col span={24}>
                    <Card className="pl-2 ">

                    </Card>
                </Col> */}
                <Col span={24}>
                    <Card className="">
                        <div className="relative map-container">
                            {/* <BeachSample beaches={beaches} /> */}
                            {API.isLoading ? (
                                <div className="h-full w-full flex justify-center items-center">
                                    <Spin size="large" />
                                </div>
                            ) : beaches?.length ? (
                                <Mapbeach beaches={beaches}
                                    identity={props?.profile?.role}
                                    toggleShowSpots={toggleShowSpots}
                                    isShowSpot={isShowSpot}
                                    setSpotData={setSpotData}
                                    spotData={spotData}
                                    setIsRefresh={setIsRefresh}
                                />
                            ) : (
                                <Alert
                                    showIcon
                                    message="No beach found for particular filter"
                                />
                            )}
                            {/* <BeachMap beaches={beaches} /> */}
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default MapPin

export function setProximity(beaches, lessDistance = 100) {
    const closeBeaches = new Set();
    beaches.forEach((beach, index) => {
        for (let i = 0; i < beaches.length; i++) {
            if (i !== index) {
                const distance = getDistance(
                    beach.lat,
                    beach.long,
                    beaches[i].lat,
                    beaches[i].long
                );
                if (distance < lessDistance) {
                    closeBeaches.add(beach.id);
                    closeBeaches.add(beaches[i].id);
                }
            }
        }
    });

    return beaches.map((beach) => ({
        ...beach,
        isClose: closeBeaches.has(beach.id),
    }));
}

export function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000; // convert to meters
    return distance;
}
