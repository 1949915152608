import React, { useRef, useEffect, useState, useCallback } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapBottomButtons from "../map/MapBottomButtons";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import { ROLES } from "../../../util/Route";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const Mapbeach = ({ beaches, identity, toggleShowSpots, isShowSpot, spotData, setSpotData, setIsRefresh }) => {
    let isPopupBeachData = Object.keys(beaches).length > 0;
    const [createOpen, setCreateOpen] = useState(null);
    const [enableToSpot, setEnableToSpot] = useState(false);
    const API = useHttp();
    const [loaded, setLoaded] = useState(false);
    const [isLoadingCount, setIsLoadingCount] = useState(true);
    const [formData, setFormData] = useState({});

    const mapContainer = useRef(null);
    const map = useRef(null);
    const naviagate = useNavigate();
    const SetBeaches = () => {
        if (!mapContainer.current || !beaches?.length || !loaded) return;
        if (map.current.getSource("beaches")) {
            map.current.getSource("beaches").setData({
                type: "FeatureCollection",
                features: beaches.map((beach) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [beach.long, beach.lat],
                    },
                    properties: beach,
                })),
            });
        } else {
            map.current.addSource("beaches", {
                type: "geojson",

                data: {
                    type: "FeatureCollection",
                    crs: {
                        type: "name",
                        properties: {
                            name: "urn:ogc:def:crs:OGC:1.3:CRS84",
                        },
                    },
                    features: beaches.map((beach) => ({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [beach.long, beach.lat, 0],
                        },
                        properties: beach,
                    })),
                },
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            });
            map.current.addLayer({
                id: "clusters",
                type: "circle",
                source: "beaches",
                filter: ["has", "point_count"],
                paint: {
                    // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 25px circles when point count is between 100 and 750
                    //   * Pink, 30px circles when point count is greater than or equal to 750
                    "circle-color": [
                        "step",
                        ["get", "point_count"],
                        "#f1f075",
                        100,
                        "#f1f075",
                        750,
                        "#f28cb1",
                    ],
                    "circle-radius": [
                        "step",
                        ["get", "point_count"],
                        20,
                        100,
                        25,
                        750,
                        30,
                    ],
                },
            });

            map.current.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "beaches",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": ["get", "point_count_abbreviated"],
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12,
                },
            });
            map.current.loadImage("/Marker4-4x.png", (error, image) => {
                if (error) throw error;
                // const scaledImage = new Image();
                // scaledImage.src = image;
                map.current.addImage("beach-icon", image);

                // Add the symbol layer with the beach icon and beach name
                map.current.addLayer({
                    id: "unclustered-point",
                    type: "symbol",
                    source: "beaches",
                    filter: ["!", ["has", "point_count"]],
                    layout: {
                        "icon-image": "beach-icon",
                        "icon-size": 0.22,
                        "icon-allow-overlap": true,
                        "text-field": ["get", "name"], // Assuming your data source has a 'beachName' property
                        "text-size": 18, // Adjust the size of the text
                        "text-offset": [1, 0], // Position the text slightly below the icon
                        "text-anchor": "left", // Anchor text to be above the icon
                        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    },
                    paint: {
                        "text-color": "#FF6B00", // Set text color FF6B00
                        "text-halo-color": "#fff  ", // Optional: Add a halo around the text for better readability
                        "text-halo-width": 1, // Optional: Set halo width
                    },
                });
            });

            // inspect a cluster on click
            map.current.on("click", "clusters", (e) => {
                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ["clusters"],
                });
                const clusterId = features[0].properties.cluster_id;
                map.current
                    .getSource("beaches")
                    .getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;

                        map.current.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom,
                        });
                    });
            });

            const popup = new mapboxgl.Popup({
                // closeButton: false,
                // closeOnClick: false,
            });
            map.current.on("click", "unclustered-point", (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();

                const { locationAdress } = e.features[0].properties;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                popup
                    .setLngLat(coordinates)
                    .setHTML(`${locationAdress}`)
                    .addTo(map.current);
            });

            map.current.on("mouseenter", "clusters", () => {
                map.current.getCanvas().style.cursor = "pointer";
            });
            map.current.on("mouseleave", "clusters", () => {
                map.current.getCanvas().style.cursor = "";
            });
            map.current.addControl(new mapboxgl.ScaleControl());
        }
    };

    const customSpotData = () => {
        if (!mapContainer.current || !loaded) return;
        // Add custom spot markers
        if (map?.current?.getSource("custom-spots")) {
            map.current.getSource("custom-spots").setData({
                type: "FeatureCollection",
                features: !spotData?.length ? [] : spotData.map((spot) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [spot?.GeoLoc?.coordinates?.[1], spot?.GeoLoc?.coordinates?.[0]], // Assuming spot.long and spot.lat are available
                    },
                    properties: spot,
                })),
            });
        } else {
            map.current.addSource("custom-spots", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    crs: {
                        type: "name",
                        properties: {
                            name: "EPSG:4326",
                        },
                    },
                    features: !spotData?.length ? [] : spotData.map((spot) => ({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [spot?.GeoLoc?.coordinates?.[1], spot?.GeoLoc?.coordinates?.[0], 0], // Assuming spot.long and spot.lat are available
                        },
                        properties: spot,
                    })),
                },
            });

            map.current.loadImage("/customPin.png", (error, image) => {
                if (error) throw error;
                // const scaledImage = new Image();
                // scaledImage.src = image;
                map.current.addImage("custom-spots", image);

                // Add the symbol layer with the beach icon and beach name
                map.current.addLayer({
                    id: "custom-spots-layer",
                    type: "symbol",
                    source: "custom-spots",
                    filter: ["!", ["has", "point_count"]],
                    layout: {
                        "icon-image": "custom-spots",
                        "icon-size": 0.9,
                        "icon-allow-overlap": true,
                        "text-field": ["get", "category"], // Assuming your data source has a 'beachName' property
                        "text-size": 18, // Adjust the size of the text
                        "text-offset": [1, 0], // Position the text slightly below the icon
                        "text-anchor": "left", // Anchor text to be above the icon
                        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    },
                    paint: {
                        "text-color": "#37acf4", // Set text color FF6B00
                        "text-halo-color": "#fff  ", // Optional: Add a halo around the text for better readability
                        "text-halo-width": 1, // Optional: Set halo width
                    },
                });
            });

            map.current.on("click", "custom-spots-layer", (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();
                const { locationAddress, title, category } = e?.features?.[0]?.properties; // Assuming spots have 'locationAddress'
                const jsonObject = JSON.parse(e?.features?.[0]?.properties?.GeoLoc);
                setFormData({
                    ...e?.features?.[0]?.properties,
                    lat: jsonObject?.coordinates?.[1],
                    long: jsonObject?.coordinates?.[0]
                })
                // const popup = new mapboxgl.Popup().setLngLat(coordinates).setHTML(`${category}`).addTo(map.current);
            });
        }
    }

    useEffect(() => {
        const handleMapClick = (e) => {
            if (!enableToSpot) return; // Ensure the condition works correctly
            const coordinates = e.lngLat;
            setCreateOpen({
                ...coordinates
            });
        };

        if (enableToSpot && map.current) {
            map.current.on("click", handleMapClick); // Add the event listener
        }

        return () => {
            if (map.current) {
                map.current.off("click", handleMapClick); // Remove the event listener when `enableToSpot` changes or component unmounts
            }
        };
    }, [enableToSpot]);
    useEffect(() => {
        customSpotData()
    }, [spotData, loaded])

    // useEffect(() => {
    //     if (isShowSpot) {
    //         // setSpotData([])
    //         // naviagate("app/map-pin")
    //         // map?.current?.removeSource('custom-spots');
    //         // console.log(map?.current)
    //         // setLoaded(false);
    //         // setSpotData([])
    //         // map?.current?.getSource('custom-spots')?.reload();
    //         // console.log("dskfhjdhfkhdkfhkdfhkhkf");
    //         // map?.current?.getSource('custom-spots')?.setData({
    //         //     crs: {
    //         //         type: "name",
    //         //         properties: {
    //         //             name: "EPSG:4326",
    //         //         },
    //         //     },
    //         //     features: [],
    //         // });
    //     } else {

    //         // API.sendRequest(
    //         //     CONSTANTS.API.customSpot.getSpot,
    //         //     (res) => {
    //         //         setSpotData(res?.data?.rows)
    //         //     },
    //         // );
    //     }
    // }, [isShowSpot]);
    useEffect(() => {
        if (!beaches?.length) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/satellite-streets-v12",
            // center: [69.10855, 22.455],
            // center: [-71.246587, 41.4889363],
            // zoom: 10,
            center: [-110.25, 36.46],
            // projection: { name: "mercator" },
            pitch: 0,
            bearing: 0,
            // center: [-119.25, 36.46],
            // center: [-75.0, 43.0],
            zoom: 5,
        });
        map.current.on("load", () => {
            map.current.addSource("mapbox-dem", {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxzoom: 14,
            });
            map.current.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
            map.current.setPitch(60);
            map.current.setBearing(-17.6);
            map.current.addControl(new mapboxgl.FullscreenControl());
            setLoaded(true);
        });


        return () => map.current.remove();
    }, []);
    useEffect(() => {
        SetBeaches();
    }, [beaches, loaded]);

    return (
        <>
            <div className="relative">
                <div ref={mapContainer} className="map-container" />
                <MapBottomButtons
                    map={map}
                    isPopupBeachData={isPopupBeachData}
                    setEnableToSpot={setEnableToSpot}
                    enableToSpot={enableToSpot}
                    isLoadingCount={isLoadingCount}
                    isShowSpot={isShowSpot}
                    toggleShowSpots={toggleShowSpots}
                />
                <ModalFormCreator
                    loading={API.isLoading}
                    open={
                        createOpen &&
                        [
                            ROLES.ADMIN,
                            ROLES.DATA,
                            ROLES.SUPER_ADMIN,
                        ].some((el) => el === identity)
                    }
                    // edit={true}
                    // editText={"Delete"}
                    // onEdit={() => {
                    // }}
                    Delete
                    onDelete={(clear) => {
                        API.sendRequest(
                            apiGenerator(CONSTANTS.API.customSpot.deleteSpot, { dataId: formData?.id }),
                            (res) => {
                                setCreateOpen(null);
                                setFormData({})
                                setIsRefresh(pr => !pr)
                                clear();
                            }, "Delete Successfully"
                        )
                    }}
                    DeleteTitle={"spot"}
                    onCreate={(v, clear) => {
                        const payload = { ...v };
                        // payload.AllBeachId = beachData?.id;
                        payload["GeoLoc"] = {
                            type: "Point",
                            coordinates: [
                                createOpen?.lat,
                                createOpen?.lng,
                            ]
                        }

                        if (payload.category) {
                            if (Object?.keys(formData)?.length > 0) {
                                API.sendRequest(
                                    apiGenerator(CONSTANTS.API.customSpot.updateSpot, {
                                        dataId: formData?.id
                                    }),
                                    (res) => {
                                        setCreateOpen(null);
                                        setFormData({})
                                        setIsRefresh(pr => !pr)
                                        clear();
                                    },
                                    payload,
                                    "Spot Edit Successfully"
                                );
                            } else {
                                API.sendRequest(
                                    CONSTANTS.API.customSpot.createSpot,
                                    (res) => {
                                        setCreateOpen(null);
                                        setFormData({})
                                        setIsRefresh(pr => !pr)
                                        clear();
                                    },
                                    payload,
                                    "Spot Added Successfully"
                                );
                            }

                        } else {
                            setCreateOpen(null);
                            clear();
                        }


                    }}
                    onCancel={() => {
                        setCreateOpen(null);
                        setFormData({})
                    }}
                    menuFields={CONSTANTS.FORM_FIELD.MAP_CUSTOM_SPOT}
                    formData={formData}
                    name={`${Object?.keys(formData)?.length > 0 ? "Edit" : "Add"} a spot (${formData?.lat ? formData?.lat?.toFixed(6) : createOpen?.lat?.toFixed(6)}, ${formData?.long ? formData?.long?.toFixed(6) : createOpen?.lng?.toFixed(6)})`}
                    SubmitName={"Submit"}
                />
            </div>
        </>
    )
}

export default Mapbeach