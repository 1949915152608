import { Breadcrumb, Button, Card, Col, Image, Progress, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANTS, {
  ActionEnumObject,
  convertToPST,
} from "../../../../util/constant/CONSTANTS";
import { ROLES, ROUTES, appRoot } from "../../../../util/Route";
import BeachMain from "./BeachMain";
import Amenities from "./Amenities";
import Photos from "./Photos";
import Setting from "./Setting";
import Summary from "./Summary";
import useHttp from "../../../../hooks/use-http";
import BeachBasicDetail from "./BeachBasicDetail";
import { FinalImageGenerator, apiGenerator } from "../../../../util/functions";
import { MdRefresh } from "react-icons/md";
import { FaFlag, FaRegFlag } from "react-icons/fa";
// import { LuFlag } from "react-icons/lu";
import NoCoverImage from "../../../../../src/asset/image/no-cover-image.jpg";
import BeachFlagComponent from "./BeachFlagComponent";
import BeachSuggestions from "../../suggestions/BeachSuggestions";
import BeachFAQTab from "./BeachFaq";
// import { GrFlag, GrFlagFill } from "react-icons/gr";
const BeachIDWisePage = (props) => {
  const param = useParams();
  const [beachData, SetBeachData] = useState({});
  const [beachAnalyticData, SetBeachAnalyticData] = useState({});
  const [refresh, setRefresh] = useState(true);
  const navigate = useNavigate();
  const API = useHttp();

  useEffect(() => {
    const FinOneBeach = { ...CONSTANTS?.API.Beach.getAll };
    FinOneBeach.endpoint = `${FinOneBeach?.endpoint}/${param?.country}/${param?.state}/${param?.city}/${param?.name}`;
    API.sendRequest(FinOneBeach, (res) => {
      if (!!!res?.data || !!!res?.data?.id) {
        navigate(`${appRoot}/${ROUTES.BEACH}`);
      }
      const BeachAnaAPI = apiGenerator(CONSTANTS.API.BeachWeight, {
        beachId: res?.data?.id,
      });
      API.sendRequest(
        BeachAnaAPI,
        (resAna) => {
          const { weightElements: BeachWeight, weights } = resAna?.data;
          // const WeightsNew = {
          //   ...weights,
          // };
          // if (BeachWeight && typeof BeachWeight === "object") {
          //   Object.keys(BeachWeight).map((key) => {
          //     WeightsNew[key] *= BeachWeight[key];
          //   });
          // }
          const WeightsNew = { ...weights };
          let weightAll = 0;
          // console.log(BeachWeight, {...WeightsNew}, WeightsNew);
          if (BeachWeight && typeof BeachWeight === "object") {
            Object.keys(weights).map((key) => {
              if (BeachWeight[key]) {
                WeightsNew[key] *= BeachWeight[key];
              } else {
                WeightsNew[key] = 0;
              }
            });
            weightAll =
              +WeightsNew?.imageElements +
              +WeightsNew?.videoElements +
              +WeightsNew?.basicDetailElements +
              +WeightsNew?.descriptionElements;
          }
          // const weightAll =
          //   +WeightsNew?.imageElements +
          //   +WeightsNew?.videoElements +
          //   +WeightsNew?.basicDetailElements +
          //   +WeightsNew?.descriptionElements;
          SetBeachAnalyticData({
            ...resAna?.data,
            weightAll,
            WeightsNew,
            // weightAll:
            //   +weights?.imageElements +
            //   +weights?.videoElements +
            //   +weights?.basicDetailElements +
            //   +weights?.descriptionElements,
          });
          // SetBeachData({
          //   ...res?.data,
          //   weight:
          //     +weights?.imageElements +
          //     +weights?.videoElements +
          //     +weights?.basicDetailElements +
          //     +weights?.descriptionElements,
          // });
        },
        null,
        null,
        () => { }
      );
      SetBeachData(res?.data);
    });
  }, [refresh]);
  // useEffect(() => {
  //   if (
  //     [
  //       // ROLES.ADMIN,
  //       ROLES.DATA,
  //       ROLES.IMAGE,
  //       ROLES.INTERN,
  //       ROLES.SEO,
  //       // ROLES.SUPER_ADMIN,
  //       // ROLES.SUPERUSER,
  //       ROLES.WRITER,
  //     ].some((el) => el === props?.profile?.role) &&
  //     beachData?.id
  //   ) {
  //     API.sendRequest(
  //       CONSTANTS.API.BeachUser.getAll,
  //       (res) => {
  //         console.log(res);
  //       },
  //       { AllBeachId: beachData?.id, userId: props?.profile?.id }
  //     );
  //   }
  // }, [props?.profile?.role, beachData]);
  const FlagToggle = () => {
    if (
      beachData?.id &&
      [
        ROLES.ADMIN,
        ROLES.DATA,
        // ROLES.IMAGE,
        // ROLES.INTERN,
        // ROLES.SEO,
        ROLES.SUPER_ADMIN,
        // ROLES.SUPERUSER,
        // ROLES.WRITER,
      ].some((el) => el === props?.profile?.role)
    ) {
      const UpadteBeachMain = apiGenerator(CONSTANTS.API.BeachMain.update, {
        id: beachData?.id,
      });
      API.sendRequest(
        UpadteBeachMain,
        () => {
          setRefresh((prev) => !prev);
        },
        { isFlag: !!!beachData?.isFlag }
      );
    }
  };

  return (
    <>
      <Breadcrumb
        className="mx-2 my-6"
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            href: `${appRoot}/${ROUTES.BEACH}`,
            title: "All Beaches",
          },
          {
            title: beachData?.name || "Beach Name",
          },
        ]}
      />

      <Card>
        <Row gutter={[16, 16]} className="mb-4">
          <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={20}>
            <Row>
              <Col className="pr-3">
                <Image
                  src={
                    // beachData?.images?.[0]?.imageUrl
                    beachData?.images?.[0]?.imageUrl
                      ? FinalImageGenerator(beachData?.images?.[0], 1600)
                      : NoCoverImage
                    // generateBase64Image(200, 400, "#4a4a4a60", {
                    //     text: "No Cover Photos",
                    //     color: "black",
                    //     font: "26px Arial",
                    //   })
                    // "https://images.sandee.com/country/Croatia.avif"
                  }
                  preview={!!beachData?.images?.[0]?.imageUrl}
                  className=" max-h-28 rounded-xl mr-3 aspect-video pointer-events-none cursor-pointer"
                />
              </Col>

              <Col className=" flex-col justify-center">
                <div className=" mb-2">
                  <p className=" text-2xl dark:text-neutral-950">
                    {beachData?.name || "Beach Name"}
                  </p>
                  <p className=" text-lg dark:text-gray-700">
                    {beachData?.city
                      ? `${beachData?.city?.name}, ${beachData?.city?.state?.name}, ${beachData?.city?.state?.country?.name}`
                      : "Location of Beach"}
                  </p>
                </div>

                <Progress
                  // strokeLinecap="butt"
                  // type="dashboard"
                  percent={beachAnalyticData?.weightAll?.toFixed(2)}
                  strokeColor={
                    beachAnalyticData?.weightAll > 75
                      ? "green"
                      : beachAnalyticData?.weightAll > 50
                        ? "orange"
                        : "red"
                  }

                // size="small"
                // size={50}
                />
                <div className=" flex gap-2 flex-wrap">
                  <p className=" text-black font-normal">
                    <span className=" font-bold">Cover Photos : </span>{" "}
                    <span className=" text-orange-500">
                      {" "}
                      {beachAnalyticData?.WeightsNew?.imageElements ?? 0}%
                    </span>{" "}
                    {`(32%)`}
                  </p>
                  <p className=" text-black font-normal">
                    <span className=" font-bold">Video : </span>{" "}
                    <span className=" text-orange-500">
                      {" "}
                      {beachAnalyticData?.WeightsNew?.videoElements ?? 0}%
                    </span>{" "}
                    {`(18%)`}
                  </p>
                  <p className=" text-black font-normal">
                    <span className=" font-bold">Basic Detail : </span>{" "}
                    <span className=" text-orange-500">
                      {" "}
                      {beachAnalyticData?.WeightsNew?.basicDetailElements ?? 0}%
                    </span>{" "}
                    {`(18%)`}
                  </p>
                  <p className=" text-black font-normal">
                    <span className=" font-bold">Description : </span>{" "}
                    <span className=" text-orange-500">
                      {" "}
                      {beachAnalyticData?.WeightsNew?.descriptionElements ?? 0}%
                    </span>{" "}
                    {`(32%)`}
                  </p>
                </div>
              </Col>
              {/* <Col className=" mx-2">
                <Button danger>
                  <FaEdit />
                </Button>
              </Col> */}
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={6}
            xxl={4}
            className="flex items-center justify-center flex-col"
          >
            {/* <Row> */}
            <Button
              href={`https://sandee.com/${beachData?.city?.state?.country?.slug}/${beachData?.city?.state?.slug}/${beachData?.city?.slug}/${beachData?.nameSlug}`}
              target="_blank"
            >
              View Beach
            </Button>
            <p className=" text-sm dark:text-neutral-950 my-3">
              Last Updated : {convertToPST(beachData?.updatedAt)}
            </p>
            {/* </Row> */}
          </Col>
        </Row>

        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          tabBarStyle={{ marginBottom: "0" }}
          tabBarGutter={12}
          tabBarExtraContent={{
            // left: [
            //   // ROLES.ADMIN,
            //   ROLES.DATA,
            //   ROLES.IMAGE,
            //   ROLES.INTERN,
            //   ROLES.SEO,
            //   // ROLES.SUPER_ADMIN,
            //   // ROLES.SUPERUSER,
            //   ROLES.WRITER,
            // ].some((el) => el === props?.profile?.role) && (
            //   <div className="flex justify-center items-center gap-2">
            //     Raju Bhai
            //   </div>
            // ),
            right: (
              <div className="flex justify-center items-center gap-2">
                {/* <FaRegFlag
                  className={`${
                    !beachData?.isFlag ? "text-[#000]" : "text-red-600 hidden"
                  } cursor-pointer mx-2 text-lg`}
                  onClick={FlagToggle}
                />
                <FaFlag
                  className={`${
                    !beachData?.isFlag ? "text-[#000] hidden" : "text-red-600 "
                  } cursor-pointer mx-2 text-lg`}
                  onClick={FlagToggle}
                /> */}
                <BeachFlagComponent
                  API={API}
                  beachData={beachData}
                  setRefresh={setRefresh}
                  identity={props?.profile?.role}
                />
                <Button
                  // shape="circle"
                  type="text"
                  className="flex items-center justify-center"
                  loading={API.isLoading}
                  onClick={() => {
                    setRefresh((prev) => !prev);
                  }}
                >
                  <MdRefresh className="text-2xl" />
                </Button>
              </div>
            ),
          }}
          items={[
            {
              label: `Beach Main`,
              key: 1,
              children: (
                <BeachMain
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                  notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? beachData?.taggedBeach?.[0]?.action?.findIndex(
                //         (el) => el === ActionEnumObject?.BEACH_MAIN
                //       ) !== -1
                //     : true
                // }
                />
              ),
            },
            {
              label: `Beach Basic Details`,
              key: 2,
              children: (
                <BeachBasicDetail
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                  notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? beachData?.taggedBeach?.[0]?.action?.findIndex(
                //         (el) => el === ActionEnumObject?.BEACH_BASIC_DETAILS
                //       ) !== -1
                //     : true
                // }
                />
              ),
            },
            {
              label: `Data Points`,
              key: 3,
              children: (
                <Amenities
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                // notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? !(
                //         beachData?.taggedBeach?.[0]?.action?.findIndex(
                //           (el) => el === ActionEnumObject?.BEACH_CATEGORY
                //         ) !== -1
                //       )
                //     : false
                // }
                />
              ),
            },
            {
              label: `Summary`,
              key: 4,
              children: (
                <Summary
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                  notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? beachData?.taggedBeach?.[0]?.action?.findIndex(
                //         (el) => el === ActionEnumObject?.BEACH_DESCRIPTION
                //       ) !== -1
                //     : true
                // }
                />
              ),
            },
            {
              label: `Photos`,
              key: 5,
              children: (
                <Photos
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                  notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? beachData?.taggedBeach?.[0]?.action?.findIndex(
                //         (el) => el === ActionEnumObject?.BEACH_IMAGES
                //       ) !== -1
                //     : true
                // }
                />
              ),
            },

            {
              label: `Settings`,
              key: 6,
              children: (
                <Setting
                  beachData={beachData}
                  refresher={() => {
                    setRefresh((prev) => !prev);
                  }}
                  identity={props?.profile?.role}
                  notdisabled
                // notdisabled={
                //   props?.profile?.role === ROLES.INTERN
                //     ? beachData?.taggedBeach?.[0]?.action?.findIndex(
                //         (el) => el === ActionEnumObject?.BEACH_SEO
                //       ) !== -1
                //     : true
                // }
                />
              ),
            },
            {
              label: `Suggestions`,
              key: 7,
              children: (
                <BeachSuggestions
                  {...props}
                  extraQuery={{
                    AllBeachId: beachData?.id,
                  }}

                />
              ),
            },
            {
              label: `FAQs`,
              key: 8,
              children: (
                <BeachFAQTab
                  {...props}
                  extraQuery={{
                    AllBeachId: beachData?.id,
                  }}
                />
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};

export default BeachIDWisePage;
