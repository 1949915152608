"use client";
import React, { useEffect, useRef, useState } from "react";
// import algoliasearch from "algoliasearch/lite";

import {
    ArrowIcon,
    BecahIcon,
    BlogIcon,
    CancelIcon,
    ListIcon,
    PinLocation,
    SearchIcon,
    StateIcon,
} from "../../asset/icons.js";
// import { SearchElastic } from "@/app/(HomeHeader)/action";
// import { useRouter } from "next/navigation";
import { Link, useNavigate } from "react-router-dom";
import useHttp from "../../hooks/use-http.js";
import CONSTANTS from "../../util/constant/CONSTANTS.js";
import { apiGenerator } from "../../util/functions.js";
// const AlgoliaSearchActive = false;
// const SearchBarConfig = {
//     development: {
//         AppId: process.env.REACT_APP_ALGOLIA_APP_ID,
//         APIkey: process.env.REACT_APP_ALGOLIA_API_KEY,
//         DBIndex: process.env.REACT_APP_ALGOLIA_DB_INDEX,
//     },
//     production: {
//         AppId: process.env.REACT_APP_ALGOLIA_APP_ID,
//         APIkey: process.env.REACT_APP_ALGOLIA_API_KEY,
//         DBIndex: process.env.REACT_APP_ALGOLIA_DB_INDEX,
//     },
// };

let isUserInteracting = false;
let rotationTimeout;
const SearchBar = ({
    onTop = false,
    onMap = false,
    setIsFocus = () => { },
    mapData = {
        map: null,
        mapboxgl: null,
        isPopupBeachData: false,
    },
    rotationAnimation = null,
    isRotation = false,
    startRotation = () => { },
    stopRotation = () => { },
    restartRotationWithDelay = () => { },
    initialquery = "",
    extraFunction = () => { },
}) => {
    // const router = useRouter();
    const navigate = useNavigate();
    const API = useHttp();
    // const [searchClient] = useState(
    //     algoliasearch(
    //         SearchBarConfig[process.env.REACT_APP_PUBLIC_ENV].AppId,
    //         SearchBarConfig[process.env.REACT_APP_PUBLIC_ENV].APIkey
    //     )
    // );
    const [query, setQuery] = useState(initialquery);
    const [hits, setHits] = useState([]);
    const searchInputRef = useRef(null);
    // const delayedSearch = (value) => {
    //     const index = searchClient.initIndex(
    //         SearchBarConfig[process.env.REACT_APP_PUBLIC_ENV].DBIndex
    //     );

    //     index
    //         .search(value)
    //         .then(({ hits }) => {
    //             setHits(hits);
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };
    const delayedEalsticSearch = (key, value) => {
        try {
            API.sendRequest(apiGenerator(CONSTANTS.API.searchElastic, {
                search: key,
                searchValue: value
            }), (res) => {
                setHits(res?.data);
            })
        } catch (error) {
            console.log(error);
        }

        // SearchElastic({
        //     search: key,
        //     value,
        // })
        //     .then((res) => {
        //         setHits(res);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            if (!!!query) {
                setHits([]);
                return;
            }
            // if (AlgoliaSearchActive) {
            //     delayedSearch(query);
            // } else 
            if (onMap) {
                delayedEalsticSearch("searchByName", query);
                // delayedEalsticSearch("SearchByAll", query);
            } else {
                delayedEalsticSearch("SearchByAll", query);
            }
        }, 400);

        return () => clearTimeout(getData);
    }, [query]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target)
            ) {
                setQuery("");
                // extraFunction();
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    const [isFocused, setIsFocused] = useState(false);
    // console.log(isFocused, "focus")
    // const rotationAnimation = useRef(null);

    return onTop ? (
        <div
            className={`absolute mr-10 md:mr-0 md:relative rounded-full max-w-[490px]   transition-all duration-700 z-[999]`}
            ref={searchInputRef}
        >
            <div className="input-container-t8 ">
                <input
                    // type="search"
                    name="text"
                    className="input-t8 !text-[14px] placeholder:!text-[14px]"
                    placeholder="Search by Country, State, City, or Beach"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    autoComplete="off"
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                />
                <div className="icon-t8 flex justify-center items-center">
                    <SearchIcon
                        className={`w-6 h-6 group-focus-within:text-[#7D7D7D] text-sandee-blue fill-white`}
                    />
                </div>
            </div>
            <div className=" relative">
                <div
                    className={`absolute w-full z-10 mt-10 rounded-b-3xl px-[1px] h-0 group-focus-within:w-full group-focus-within:h-full `}
                >
                    <div
                        className={`transition-all duration-1000 ease-in-out  ${query.trim().length && hits?.length
                            ? "overflow-y-scroll opacity-100"
                            : " !h-0 !pt-0 overflow-y-hidden no-scrollbar"
                            }  -mt-9 bg-white rounded-b-3xl  shadow-xl max-h-[400px] overflow-y-scroll`}
                    >
                        {hits.map((hit) => (
                            <div key={hit.objectID}>
                                <Link
                                    hrefLang="en-us"
                                    className="flex  mx-3 my-2 gap-4 "
                                    // target="_top"
                                    href={LinkGenrationForFilter[hit.type](hit)}
                                    onClick={() => {
                                        setQuery("");
                                        extraFunction();
                                    }}
                                >
                                    <div className="p-2 bg-[#65195f0d] rounded-full aspect-square w-[40px] h-[40px] flex items-center justify-center ">
                                        <SearchIcons type={hit?.type} />
                                    </div>
                                    <SearchSingleList hit={hit} query={query} />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    ) : onMap ? (
        <div
            className="relative w-full rounded-md md:min-w-[420px] max-w-[490px] container"
            ref={searchInputRef}
        >
            <div className=" relative z-40">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pe-3 ms-2 pointer-events-none rounded-full">
                    <SearchIcon className="w-4 h-4 text-[#7D7D7D] bg-white fill-white " />
                </div>
                <input
                    type="text"
                    id="simple-search"
                    className={`border border-gray-400 transition-all duration-500  focus:outline-none outline-none  focus:ring-0 ring-0   block w-full pe-8  rounded-[9px] text-gray-600 !pl-[45px] p-2
         ${query?.trim()?.length ? "BoxShadowSearch" : ""}
        `}
                    autoFocus
                    autoComplete="off"
                    placeholder="Search by Beach Name"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <div className="absolute inset-y-0 end-0 flex items-center ps-3 pe-3 ms-2 cursor-pointer rounded-full">
                    <CancelIcon
                        className={` transition-all duration-500 ${query.trim().length ? "" : "hidden "
                            } w-6 h-6 text-[#7D7D7D] fill-[#7D7D7D] stroke-[#7D7D7D]`}
                        onClick={() => {
                            setQuery("");
                        }}
                    />
                </div>
            </div>

            <div className="absolute z-30 w-full rounded-b-3xl px-[1px] overflow-hidden">
                <div
                    className={`transition-all duration-1000 ease-in-out overflow-x-hidden ${query.trim().length && hits?.length
                        ? "pt-10 overflow-y-scroll opacity-100"
                        : " !h-0 !pt-0 overflow-y-hidden no-scrollbar"
                        }  -mt-9 bg-white rounded-b-3xl  max-h-[400px] overflow-y-scroll`}
                >
                    {hits?.map((hit) => (
                        <div key={hit.objectID}>
                            <div
                                hrefLang="en-us"
                                className="flex  mx-3 my-2 gap-4 cursor-pointer"
                                // target="_top"
                                // href={LinkGenrationForFilter["map"](hit)}
                                onClick={() => {
                                    if (!mapData?.map) return;
                                    localStorage.setItem("isRotation", 1);

                                    // Set up marker on the map
                                    new mapData.mapboxgl.Marker({ color: "blue" })
                                        .setLngLat([
                                            hit?.GeoLoc?.coordinates[0],
                                            hit?.GeoLoc?.coordinates[1],
                                        ])
                                        .addTo(mapData?.map?.current)
                                        .getElement()
                                    // Stop ongoing rotation
                                    stopRotation(true);
                                    mapData?.map?.current?.flyTo({
                                        center: [
                                            hit?.GeoLoc?.coordinates[0],
                                            hit?.GeoLoc?.coordinates[1],
                                        ],
                                        zoom: 17,
                                        easing(t) {
                                            if (t == 1) {
                                                startRotation();
                                                mapData?.map.current.on('mousedown', () => {
                                                    isUserInteracting = true;
                                                    stopRotation(isUserInteracting);
                                                });

                                                mapData?.map.current.on('dragstart', () => {
                                                    isUserInteracting = true;
                                                    stopRotation(isUserInteracting);
                                                });

                                                mapData?.map.current.on('dragend', () => {
                                                    isUserInteracting = false;
                                                    if (!+localStorage.getItem("isRotation")) {
                                                        stopRotation();
                                                    } else {
                                                        restartRotationWithDelay();
                                                    }
                                                });

                                            }
                                            return t;
                                        },
                                        speed: 1.5, // Adjust speed for smoothness
                                        curve: 1, // Smoothness of the flight path
                                        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                                    })

                                    if (mapData?.isPopupBeachData) {
                                        // router.push("/map");
                                        // navigate("/map")
                                    }
                                    setQuery("");
                                    extraFunction();
                                }}
                            >
                                <div className="p-2 bg-[#65195f0d] rounded-full aspect-square w-[40px] h-[40px] flex items-center justify-center ">
                                    {/* <SearchIcons type={hit?.type} /> */}
                                    <SearchIcons type={"beachMap"} />
                                </div>
                                <SearchSingleList
                                    hit={{
                                        ...hit,
                                        type: "beachMap",
                                        city: hit?.city?.name,
                                        state: hit?.state?.name,
                                        country: hit?.country?.name,
                                    }}
                                    // hit={hit}
                                    query={query}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* ) : (
      ""
    )} */}
        </div>
    ) : (
        <div
            className="relative w-full rounded-full max-w-[490px] container "
            ref={searchInputRef}
        >
            <div className="relative z-20">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pe-3 ms-2 pointer-events-none rounded-full">
                    <SearchIcon className="w-6 h-6 text-[#7D7D7D] bg-white fill-white " />
                </div>
                <input
                    type="text"
                    id="simple-search"
                    className={`transition-all duration-500  focus:outline-none outline-none  focus:ring-0 ring-0 focus:border-transparent  bg-gray-50 border border-gray-300 block w-full ps-10   rounded-full text-gray-600 !pl-[55px] shadow-lg ${"text-sm md:!text-sandee-base lg:!text-sandee-18 p-5 sm:p-4 leading-none"} ${query?.trim()?.length ? "BoxShadowSearch" : ""
                        }`}
                    autoFocus
                    autoComplete="off"
                    placeholder="Search by Country, State, City, or Beach"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <div className="absolute inset-y-0 end-0 flex items-center rounded-full transition-all duration-500 cursor-pointer ">
                    <div className="relative p-2">
                        <div
                            className={`transition-all duration-500 ${query.trim().length ? " rotate-90" : " "
                                } rounded-full bg-sandee-blue p-3 transition-all duration-500`}
                        >
                            <ArrowIcon
                                className={` ${query.trim().length ? "hidden " : " w-6 h-6"
                                    }  text-white fill-white stroke-white `}
                            />
                            <CancelIcon
                                className={` transition-all duration-500 ${query.trim().length ? "" : "hidden "
                                    } w-6 h-6 text-white fill-white stroke-white`}
                                onClick={() => {
                                    setQuery("");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="absolute  w-full  rounded-b-3xl px-[1px] z-10">
                <div
                    className={`transition-all duration-1000 ease-in-out  ${query.trim().length && hits?.length
                        ? "pt-10 overflow-y-scroll opacity-100"
                        : " !h-0 !pt-0 overflow-y-hidden no-scrollbar"
                        }  -mt-9 bg-white rounded-b-3xl  shadow-xl max-h-[400px] overflow-y-scroll`}
                >
                    {hits.map((hit) => (
                        <div key={hit.objectID}>
                            <Link
                                hrefLang="en-us"
                                className="flex  mx-3 my-2 gap-4"
                                // target="_top"
                                href={LinkGenrationForFilter[hit.type](hit)}
                                onClick={() => {

                                    setQuery("");
                                    extraFunction();
                                }}
                            >
                                {/* <Image
                  // priority={true}
                  width={50}
                  height={50}
                  src={`/static/icons/${hit?.type}.png`}
                  className=" p-1 h-14 w-14 rounded-full mr-3 bg-slate-100"
                  alt="Beach Image"
                /> */}
                                <div className="p-2 bg-[#65195f0d] rounded-full aspect-square w-[40px] h-[40px] flex items-center justify-center ">
                                    <SearchIcons type={hit?.type} />
                                </div>
                                <SearchSingleList hit={hit} query={query} />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            {/* ) : (
        ""
      )} */}
        </div>
    );
};

export default SearchBar;

export const SearchIcons = ({ type = "beach" }) => {
    switch (type) {
        case "beach":
            return <BecahIcon className=" fill-sandee-blue  h-4 w-4" />;
        case "city":
            return <PinLocation className=" fill-sandee-blue  h-4 w-4" />;
        case "state":
            return <StateIcon className=" fill-sandee-blue  h-4 w-4" />;
        case "country":
            return <PinLocation className=" fill-sandee-blue  h-4 w-4" />;
        case "blog":
            return <BlogIcon className=" fill-sandee-blue  h-4 w-4" />;
        case "listicle":
            return <ListIcon className=" fill-sandee-blue  h-4 w-4" />;
        default:
            return <PinLocation className=" fill-sandee-blue  h-4 w-4" />;
    }
};
export const SearchSingleList = ({ hit, query }) => {
    const searchQuery = query?.toLowerCase().trim();

    // Highlight matching text
    const highlightMatch = (name, query) => {
        if (!query || !name) return name; // Return name if no query or name
        const lowerName = name.toLowerCase();
        const startIndex = lowerName.indexOf(query);

        if (startIndex === -1) return name; // No match found

        const beforeMatch = name.slice(0, startIndex);
        const matchedText = name.slice(startIndex, startIndex + query.length);
        const afterMatch = name.slice(startIndex + query.length);

        return (
            <>
                {beforeMatch}
                <span className="font-semibold">{matchedText}</span>
                {afterMatch}
            </>
        );
    };
    // Fuzzy match based on Levenshtein distance threshold
    const doesFuzzyMatchExistInFields = (hit, query, threshold = 3) => {
        const fields = [
            hit?.name,
            hit?.city,
            hit?.state,
            hit?.country,
            hit?.island,
            hit?.blog,
            hit?.listicle,
        ];

        return fields.some((field) => {
            if (!field) return false; // Skip empty fields
            const distance = levenshteinDistance(
                field.toLowerCase(),
                query.toLowerCase()
            );
            return distance <= threshold; // Consider it a match if within threshold
        });
    };
    // // Check if a match exists in any of the fields (name, city, state, country, blog)
    // const doesMatchExistInFields = (hit, query) => {
    //   const fields = [hit?.name, hit?.city, hit?.state, hit?.country, hit?.island,hit?.blog,hit?.listicle];
    //   return fields.some((field) => field?.toLowerCase().includes(query));
    // };

    // Determine background color based on query matching across multiple fields
    const wordCount = searchQuery?.split(" ").filter(Boolean).length || 0;
    // const shouldApplyBgColor = wordCount > 2 && doesMatchExistInFields(hit, searchQuery);
    // Inside your component
    const shouldApplyBgColor =
        wordCount > 3 && doesFuzzyMatchExistInFields(hit, searchQuery, 3); // 3 is the threshold

    const bgColorClass = shouldApplyBgColor ? "bg-sandee-blue/20" : "";

    // Reusable location renderer
    const renderLocation = (hit) => (
        <p className="text-sandee-12 text-gray-400 flex items-center gap-2 capitalize">
            {hit?.city ? `${hit?.city},` : ""}
            {hit?.state ? ` ${hit?.state},` : ""}
            {hit?.country ? ` ${hit?.country}` : ""}
        </p>
    );

    // Reusable name renderer with highlight
    const renderNameWithHighlight = (name) => highlightMatch(name, searchQuery);

    // Determine hit name based on type
    const hitName = (() => {
        switch (hit?.type) {
            case "beach":
            case "beachMap":
                return hit?.name;
            case "city":
                return hit?.city;
            case "state":
                return hit?.state;
            case "country":
                return hit?.country;
            case "island":
                return hit?.island;
            case "blog":
                return hit?.blog;
            case "listicle":
                return hit?.listicle;
            default:
                return null;
        }
    })();

    // Render based on hit type
    return (
        <div
            className={`w-full flex flex-col items-start justify-center ${bgColorClass}`}
        >
            <p className="w-full text-sandee-sm">
                {renderNameWithHighlight(hitName)}
            </p>
            {(hit?.type === "beach" ||
                hit?.type === "beachMap" ||
                hit?.type === "city" ||
                hit?.type === "state" ||
                hit?.type === "island") &&
                renderLocation(hit)}
        </div>
    );
};


export const LinkGenrationForFilter = {
    city: (data) => `/${data?.countrySlug}/${data?.stateSlug}/${data?.citySlug}`,
    state: (data) => `/${data?.countrySlug}/${data?.stateSlug}`,
    country: (data) => `/${data?.countrySlug}`,
    beach: (data) =>
        `/${data?.countrySlug}/${data?.stateSlug}/${data?.citySlug}/${data?.nameSlug}`,
    island: (data) => `/island/${data?.islandSlug}`,
    blog: (data) => `/blog/${data?.slug}`,
    listicle: (data) => `/list/${data?.slug}`,
    map: (data) =>
        `/map/${data?.nameSlug}/@${data?.GeoLoc?.coordinates[1]},${data?.GeoLoc?.coordinates[0]}`,
};

export const levenshteinDistance = (a = "", b = "") => {
    const matrix = Array?.from({ length: a.length + 1 }, () =>
        Array(b.length + 1).fill(0)
    );

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1, // Deletion
                matrix[i][j - 1] + 1, // Insertion
                matrix[i - 1][j - 1] + cost // Substitution
            );
        }
    }

    return matrix[a.length][b.length];
};
